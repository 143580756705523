import React, { useState, useEffect, useCallback } from "react";
import Select, { components } from "react-select";
import { LOCAL_STORAGE_KEY } from "./AreaSearchModalWrapper";

interface SubArea {
  id: string;
  name: string;
  count: number;
}

interface Area {
  id: string;
  name: string;
  subAreas: SubArea[];
}

interface Prefecture {
  id: string;
  name: string;
  areas: Area[];
}

interface AreaSearchModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialValueString: string;
  targetParentElementId: string;
}

interface Option {
  value: string; // これはsubAreaIdと同じ値になります
  label: string;
  areaId: string;
  subAreaId: string;
}

const MAX_SELECTED_COUNT = 10;

const AreaSearchModal: React.FC<AreaSearchModalProps> = ({
  isOpen,
  onClose,
  initialValueString,
  targetParentElementId,
}) => {
  const [prefectureData, setPrefectureData] = useState<Prefecture[]>([]);
  const [selectedPrefecture, setSelectedPrefecture] =
    useState<Prefecture | null>(null);
  const [checkedAreas, setCheckedAreas] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [searchOptions, setSearchOptions] = useState<Option[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [error, setError] = useState<string | null>(null);

  const CACHE_DURATION = 10 * 60 * 1000; // 10分をミリ秒で表現

  const targetParentElement = document.getElementById(targetParentElementId);

  useEffect(() => {
    fetchPrefectureData();
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  useEffect(() => {
    if (prefectureData.length > 0) {
      const options: Option[] = prefectureData.flatMap((prefecture) =>
        prefecture.areas.flatMap((area) =>
          area.subAreas.map((subArea) => ({
            value: subArea.id,
            label: `${subArea.name} (${area.name})`,
            areaId: area.id,
            subAreaId: subArea.id,
          }))
        )
      );
      setSearchOptions(options);
    }
  }, [prefectureData]);

  useEffect(() => {
    if (prefectureData.length > 0 && searchOptions.length > 0) {
      initializeFromAttribute();
    }
  }, [prefectureData, searchOptions]);

  useEffect(() => {
    if (isOpen && searchOptions.length > 0) {
      initializeFromAttribute();
    }
  }, [isOpen, searchOptions]);

  const initializeFromAttribute = () => {
    const modalTrigger = targetParentElement.querySelector(
      "#open-area-search-modal"
    );
    const hiddenInput = targetParentElement.querySelector(
      "#selected-areas-input"
    ) as HTMLInputElement;

    if (hiddenInput) {
      hiddenInput.value = initialValueString;
    } else {
      console.error("選択されたエリアの入力要素が見つかりません");
    }

    if (modalTrigger && hiddenInput) {
      let initialValue = hiddenInput.value || initialValueString;

      // data-initial-valueが空の場合、localStorageから値を取得
      if (!initialValue) {
        const storedValue = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (storedValue) {
          initialValue = storedValue;

          // storedValueをselected-areas-inputのvalueにセット
          const selectedAreasInput = targetParentElement.querySelector(
            "#selected-areas-input"
          ) as HTMLInputElement;
          if (selectedAreasInput) {
            selectedAreasInput.value = storedValue;
          }
        }
      }

      if (initialValue) {
        const initialIds = initialValue.split(",");
        const initialOptions = searchOptions.filter((option) =>
          initialIds.some((id) => {
            return (
              option.subAreaId === id ||
              option.subAreaId.split("-").pop() === id
            );
          })
        );
        setSelectedOptions(initialOptions);

        // モーダルトリガーのテキストを更新
        modalTrigger.textContent =
          initialOptions.map((option) => option.label).join("、") || "エリア";

        // サブエリアにチェックを入れる
        const newCheckedAreas: { [key: string]: boolean } = {};
        initialOptions.forEach((option) => {
          newCheckedAreas[option.subAreaId] = true;
        });

        // エリアのチェック状態を正しく設定
        prefectureData.forEach((prefecture) => {
          prefecture.areas.forEach((area) => {
            const allSubAreasChecked = area.subAreas.every((subArea) => {
              return (
                initialIds.includes(subArea.id) ||
                initialIds.includes(subArea.id.split("-").pop())
              );
            });
            newCheckedAreas[area.id] = allSubAreasChecked;
          });
        });

        setCheckedAreas(newCheckedAreas);
      }
    }
  };

  // キャッシュを実装して10分間はAPIリクエストを新規に行わない
  const fetchPrefectureData = async () => {
    const cachedData = localStorage.getItem("prefectureData");
    const cachedTimestamp = localStorage.getItem("prefectureDataTimestamp");

    if (cachedData && cachedTimestamp) {
      const now = new Date().getTime();
      if (now - parseInt(cachedTimestamp) < CACHE_DURATION) {
        const data = JSON.parse(cachedData);
        setPrefectureData(data);
        if (data.length > 0) {
          setSelectedPrefecture(data[0]);
        }
        return;
      }
    }

    try {
      const response = await fetch("/api/area_search");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setPrefectureData(data);
      if (data.length > 0) {
        setSelectedPrefecture(data[0]);
      }

      // キャッシュにデータを保存
      localStorage.setItem("prefectureData", JSON.stringify(data));
      localStorage.setItem(
        "prefectureDataTimestamp",
        new Date().getTime().toString()
      );
    } catch (error) {
      console.error("都道府県データの取得中にエラーが発生しました:", error);
    }
  };

  const handleOutsideClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    },
    [onClose]
  );

  const handleAreaCheck = (areaId: string, isChecked: boolean) => {
    const area = selectedPrefecture?.areas.find((area) => area.id === areaId);
    if (area) {
      const newSelectedCount = isChecked
        ? selectedOptions.length + area.subAreas.length
        : selectedOptions.length -
          area.subAreas.filter((subArea) => checkedAreas[subArea.id]).length;

      if (newSelectedCount >= MAX_SELECTED_COUNT) {
        setError(`${MAX_SELECTED_COUNT}個以上選択できません`);
        return;
      }
      setError(null);

      const newCheckedAreas = { ...checkedAreas };
      newCheckedAreas[areaId] = isChecked;
      area.subAreas.forEach((subArea) => {
        newCheckedAreas[subArea.id] = isChecked;
        updateSelectedOptions(subArea.id, isChecked);
      });
      setCheckedAreas(newCheckedAreas);
    }
  };

  const handleSubAreaCheck = (
    areaId: string,
    subAreaId: string,
    isChecked: boolean
  ) => {
    if (isChecked && selectedOptions.length >= MAX_SELECTED_COUNT) {
      setError(`${MAX_SELECTED_COUNT}個以上選択できません`);
      return;
    }
    setError(null);

    const newCheckedAreas = { ...checkedAreas };
    newCheckedAreas[subAreaId] = isChecked;

    const area = selectedPrefecture?.areas.find((area) => area.id === areaId);
    if (area) {
      const allSubAreasChecked = area.subAreas.every(
        (subArea) => newCheckedAreas[subArea.id]
      );
      newCheckedAreas[areaId] = allSubAreasChecked;
    }

    setCheckedAreas(newCheckedAreas);
    updateSelectedOptions(subAreaId, isChecked);
  };

  const updateSelectedOptions = (subAreaId: string, isChecked: boolean) => {
    const option = searchOptions.find((opt) => opt.subAreaId === subAreaId);
    if (option) {
      if (isChecked) {
        setSelectedOptions((prev) => {
          if (!prev.some((opt) => opt.subAreaId === option.subAreaId)) {
            return [...prev, option];
          }
          return prev;
        });
      } else {
        setSelectedOptions((prev) =>
          prev.filter((opt) => opt.subAreaId !== subAreaId)
        );
      }
    }
  };

  const handleClearAll = () => {
    setCheckedAreas({});
    setSelectedOptions([]);
    setError(null);
  };

  const handleOnChange = (newSelectedOptions: readonly Option[]) => {
    if (newSelectedOptions.length >= MAX_SELECTED_COUNT) {
      setError(`${MAX_SELECTED_COUNT}個以上選択できません`);
      return;
    }
    setError(null);

    const newCheckedState: { [key: string]: boolean } = {};
    newSelectedOptions.forEach((option) => {
      newCheckedState[option.areaId] = true;
      newCheckedState[option.subAreaId] = true;
    });

    prefectureData.forEach((prefecture) => {
      prefecture.areas.forEach((area) => {
        const allSubAreasChecked = area.subAreas.every((subArea) =>
          newSelectedOptions.some((option) => option.subAreaId === subArea.id)
        );
        newCheckedState[area.id] = allSubAreasChecked;
      });
    });

    // 選択されたSubAreaの親のPrefectureを選択状態にする
    if (newSelectedOptions.length > 0) {
      const lastSelectedOption =
        newSelectedOptions[newSelectedOptions.length - 1];
      const selectedPrefecture = prefectureData.find((prefecture) =>
        prefecture.areas.some((area) => area.id === lastSelectedOption.areaId)
      );
      if (selectedPrefecture) {
        setSelectedPrefecture(selectedPrefecture);

        // 横スクロールの位置をPrefectureが表示位置になるようにする
        setTimeout(() => {
          const prefectureElement = document.querySelector(
            `button[data-prefecture-id="${selectedPrefecture.id}"]`
          );
          if (prefectureElement) {
            prefectureElement.scrollIntoView({
              behavior: "instant",
              inline: "center",
            });
          }
        }, 0);

        // 検索決定したSubAreaの位置まで縦スクロール
        setTimeout(() => {
          const subAreaElement = document.getElementById(
            lastSelectedOption.subAreaId
          );
          if (subAreaElement) {
            subAreaElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }, 100);
      }
    }

    setCheckedAreas(newCheckedState);
    setSelectedOptions(newSelectedOptions as Option[]);
  };

  const handleFilter = () => {
    const selectedText = selectedOptions
      .map((option) => option.label)
      .join("、");
    const selectedIds = selectedOptions
      .map((option) => option.subAreaId)
      .join(",");

    // LocalStorageに選択されたサブエリアを保存
    localStorage.setItem(LOCAL_STORAGE_KEY, selectedIds);

    const modalTrigger = targetParentElement.querySelector(
      "#open-area-search-modal"
    );
    if (modalTrigger) {
      modalTrigger.textContent = selectedText || "エリアを選択";

      const hiddenInput = targetParentElement.querySelector(
        "#selected-areas-input"
      ) as HTMLInputElement;
      if (hiddenInput) {
        hiddenInput.value = selectedIds;
      } else {
        console.error("選択されたエリアの入力要素が見つかりません");
      }
    }

    // モバイルとPCのフォームIDを定義
    const mobileFormId = "search-form-spaces-mobile";
    const pcFormId = "search-form-spaces-pc";
    const homeFormId = "search-form-spaces-home";
    // 表示されているフォームのIDを特定
    let visibleFormId: string | undefined;
    if (document.getElementById(homeFormId)?.checkVisibility()) {
      visibleFormId = homeFormId;
    } else if (document.getElementById(mobileFormId)?.checkVisibility()) {
      visibleFormId = mobileFormId;
    } else if (document.getElementById(pcFormId)?.checkVisibility()) {
      visibleFormId = pcFormId;
    }

    // フォームを送信
    const searchForm = targetParentElement.querySelector(
      `#${visibleFormId}`
    ) as HTMLFormElement;
    if (searchForm) {
      searchForm.submit();
    } else {
      console.error("検索フォームが見つかりません");
    }

    onClose();
  };

  if (!isOpen) return null;

  return (
    <div
      className="z-50 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      onClick={handleOutsideClick}
    >
      <div className="bg-white rounded-lg w-11/12 max-w-xl flex flex-col h-[90vh]">
        <div className="p-4 border-b flex justify-between items-center">
          <h2 className="text-xl font-bold">エリア</h2>
          <button className="text-2xl" onClick={onClose}>
            &times;
          </button>
        </div>

        <div className="p-4">
          <Select
            isMulti
            options={searchOptions}
            value={selectedOptions}
            onChange={handleOnChange}
            placeholder="エリア・駅を検索"
          />
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>

        <div className="flex flex-row flex-none overflow-x-auto pb-2">
          {prefectureData.map((pref) => (
            <button
              key={pref.id}
              data-prefecture-id={pref.id}
              className={`px-4 py-2 whitespace-nowrap ${
                selectedPrefecture?.id === pref.id
                  ? "font-bold border-b-2 border-green-500"
                  : ""
              }`}
              onClick={() => setSelectedPrefecture(pref)}
            >
              {pref.name}
            </button>
          ))}
        </div>

        <div className="flex-grow overflow-y-auto px-4">
          {selectedPrefecture && (
            <div>
              {selectedPrefecture.areas.map((area) => (
                <div key={area.name} className="mb-4">
                  <div className="border-b border-green-500 p-2">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={checkedAreas[area.id] || false}
                        onChange={(e) =>
                          handleAreaCheck(area.id, e.target.checked)
                        }
                      />
                      <span className="font-semibold">
                        {area.name}(
                        {area.subAreas.reduce(
                          (sum, subArea) => sum + subArea.count,
                          0
                        )}
                        )
                      </span>
                    </label>
                  </div>
                  <div className="ml-4 mt-4 grid grid-cols-2 gap-2">
                    {area.subAreas.map((subArea) => (
                      <div key={subArea.name} className="flex items-center">
                        <input
                          type="checkbox"
                          id={subArea.id.toString()}
                          className="mr-2"
                          checked={checkedAreas[subArea.id] || false}
                          onChange={(e) =>
                            handleSubAreaCheck(
                              area.id,
                              subArea.id,
                              e.target.checked
                            )
                          }
                        />
                        <label
                          htmlFor={subArea.id.toString()}
                          className="text-sm"
                        >
                          {subArea.name}({subArea.count})
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="p-4 border-t flex justify-between items-center">
          <button className="text-gray-600" onClick={handleClearAll}>
            すべてクリア
          </button>
          <button
            className="bg-green-500 text-white px-6 py-2 rounded-md flex items-center"
            onClick={handleFilter}
          >
            <svg
              className="w-5 h-5 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
            絞り込む
          </button>
        </div>
      </div>
    </div>
  );
};

export default AreaSearchModal;
