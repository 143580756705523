import React, { useState, useEffect, useCallback } from "react";
import AssetSearchModal from "./AssetSearchModal";
import Turbolinks from "turbolinks";
interface AssetsAdvantageItem {
  id: number;
  body: string;
  is_like: boolean;
  tag: string | null;
  tag_css: string | null;
}

interface AssetsAdvantageCategory {
  role: number;
  role_text: string;
  contents: AssetsAdvantageItem[];
}

export default function AssetSearchModalWrapper() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assetsAdvantage, setAssetsAdvantage] = useState<
    AssetsAdvantageCategory[]
  >([]);
  const [popularFilters, setPopularFilters] = useState<AssetsAdvantageItem[]>(
    []
  );
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>(
    {}
  );

  useEffect(() => {
    const wrapper = document.getElementById("asset-search-wrapper");
    if (wrapper) {
      const data = JSON.parse(wrapper.dataset.assetsAdvantage || "[]");
      setAssetsAdvantage(data);

      const popular = data.flatMap((category: AssetsAdvantageCategory) =>
        category.contents.filter((item: AssetsAdvantageItem) => item.is_like)
      );
      setPopularFilters(popular);

      // URLパラメータからassetsの値を取得
      const urlParams = new URLSearchParams(window.location.search);
      const assetIds = urlParams.get("assets")?.split(",").map(Number) || [];

      // チェックボックスの状態を復元
      const initialCheckedItems = assetIds.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {} as { [key: number]: boolean });

      setCheckedItems(initialCheckedItems);
    }

    // ボタンにクリックイベントを追加
    const assetButtons = document.querySelectorAll("[data-asset-id]");
    assetButtons.forEach((button) => {
      button.addEventListener("click", handleAssetButtonClick);
    });

    // クリーンアップ関数
    return () => {
      assetButtons.forEach((button) => {
        button.removeEventListener("click", handleAssetButtonClick);
      });
    };
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isModalOpen) {
        closeModal();
      }
    };
    document.addEventListener("keydown", handleEscKey);
    return () => document.removeEventListener("keydown", handleEscKey);
  }, [isModalOpen, closeModal]);

  const handleCheck = (id: number, isChecked: boolean) => {
    setCheckedItems((prev) => ({ ...prev, [id]: isChecked }));
  };

  const handleFilter = () => {
    const checkedIds = Object.entries(checkedItems)
      .filter(([_, isChecked]) => isChecked)
      .filter(([id, _]) => id !== "0")
      .map(([id, _]) => id)
      .join(",");

    // 新しいURLを作成（ベースを /search に設定）
    const newUrl = new URL("/search", window.location.origin);

    // 現在のURLから既存のクエリパラメータを取得
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.forEach((value, key) => {
      if (key !== "assets") {
        newUrl.searchParams.set(key, value);
      }
    });

    // assetsパラメータを設定
    if (checkedIds) {
      newUrl.searchParams.set("assets", checkedIds);
    }

    // turbolinksを使用した遷移
    Turbolinks.visit(newUrl.toString());
  };

  const checkboxClass =
    "mr-3 w-6 h-6 form-checkbox text-white bg-white border-1 border-gray-300 rounded focus:ring-0 focus:ring-offset-0 checked:bg-black";
  const labelClass = "text-sm align-left";

  // 検索対象から外すために、ボタンを押したときの処理
  const handleAssetButtonClick = (event: Event) => {
    const button = event.currentTarget as HTMLElement;
    const deleteAssetId = button.getAttribute("data-asset-id");
    if (deleteAssetId) {
      const currentUrl = new URL(window.location.href);
      const assets = currentUrl.searchParams.get("assets");
      if (assets) {
        const assetIds = assets.split(",").filter((id) => id !== deleteAssetId);
        if (assetIds.length > 0) {
          currentUrl.searchParams.set("assets", assetIds.join(","));
        } else {
          currentUrl.searchParams.delete("assets");
        }
      }
      // turbolinksを使用した遷移
      Turbolinks.visit(currentUrl.toString());
    }
  };

  return (
    <div className="bg-gray-50 p-6 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-extrabold">特徴・器具</h2>
        <button
          onClick={openModal}
          className="text-gray-600 hover:text-gray-800 font-medium underline"
        >
          詳細条件 &gt;
        </button>
      </div>
      <p className="text-gray-700 font-bold mb-3">人気のフィルター</p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        {popularFilters.map((item) => (
          <div key={item.id} className="flex items-center">
            <input
              type="checkbox"
              id={`popular-${item.id}`}
              value={item.id}
              className={checkboxClass}
              checked={checkedItems[item.id] || false}
              onChange={(e) => handleCheck(item.id, e.target.checked)}
            />
            <label htmlFor={`popular-${item.id}`} className={labelClass}>
              {item.body}
            </label>
          </div>
        ))}
      </div>
      <p className="text-gray-700 font-bold mt-6 mb-3">予約方法</p>
      <div className="space-y-3">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="sameday"
            value="65"
            className={checkboxClass}
            checked={checkedItems[65] || false}
            onChange={(e) => handleCheck(65, e.target.checked)}
          />
          <label htmlFor="sameday" className={labelClass}>
            当日予約可能
          </label>
        </div>
      </div>
      <button
        className="w-full bg-green-500 text-white py-4 rounded-lg mt-8 flex items-center justify-center text-xl font-bold"
        onClick={handleFilter}
      >
        <svg
          className="w-6 h-6 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
        絞り込む
      </button>
      <AssetSearchModal
        isOpen={isModalOpen}
        onClose={closeModal}
        assetsAdvantage={assetsAdvantage}
      />
    </div>
  );
}
