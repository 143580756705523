import React, { useState, useEffect, useCallback } from "react";
import AreaSearchModal from "./AreaSearchModal";

export const LOCAL_STORAGE_KEY = "selectedSubAreas";

export default function AreaSearchModalWrapper() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [targetParentElementId, setTargetParentElementId] = useState("");
  const openModal = () => setIsModalOpen(true);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  const initialValueString = (() => {
    // if (window.location.pathname == "/") {
    //   return localStorage.getItem(LOCAL_STORAGE_KEY);
    // }

    const openAreaSearchModal = document.getElementById(
      "open-area-search-modal"
    );
    const initialValue =
      openAreaSearchModal?.getAttribute("data-initial-value");
    return initialValue;
  })();

  useEffect(() => {
    if (!targetParentElementId) return;

    const button = document
      .getElementById(targetParentElementId)
      ?.querySelector("#open-area-search-modal");

    if (button) {
      button.addEventListener("click", openModal);
    }

    // ESCキーのイベントリスナーを追加
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isModalOpen) {
        closeModal();
      }
    };
    document.addEventListener("keydown", handleEscKey);

    return () => {
      if (button) {
        button.removeEventListener("click", openModal);
      }
      // クリーンアップ時にESCキーのイベントリスナーを削除
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [isModalOpen, closeModal, targetParentElementId]);

  useEffect(() => {
    const handleResize = () => {
      if (window.location.pathname == "/") {
        setTargetParentElementId("search-form-wrapper-home");
      } else if (window.innerWidth >= 768) {
        setTargetParentElementId("search-form-wrapper-pc");
      } else {
        setTargetParentElementId("search-form-wrapper-mobile");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <AreaSearchModal
        isOpen={isModalOpen}
        onClose={closeModal}
        initialValueString={initialValueString}
        targetParentElementId={targetParentElementId}
      />
    </>
  );
}
