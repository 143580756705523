import React, { useState, useEffect, useCallback } from "react";

interface AssetsAdvantage {
  id: number;
  body: string;
  role: number;
  is_like: boolean;
  tag: string | null;
  tag_css: string | null;
  tag_order: number;
}

interface AssetSearchModalProps {
  isOpen: boolean;
  onClose: () => void;
  assetsAdvantage: Record<string, AssetsAdvantage[]>;
}

const AssetSearchModal: React.FC<AssetSearchModalProps> = ({
  isOpen,
  onClose,
  assetsAdvantage,
}) => {
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>(
    {}
  );

  useEffect(() => {
    // URLパラメータからassetsの値を取得
    const urlParams = new URLSearchParams(window.location.search);
    const assetIds = urlParams.get("assets")?.split(",").map(Number) || [];

    // チェックボックスの状態を復元
    const initialCheckedItems = assetIds.reduce((acc, id) => {
      acc[id] = true;
      return acc;
    }, {} as { [key: number]: boolean });

    setCheckedItems(initialCheckedItems);
  }, []);

  const handleOutsideClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    },
    [onClose]
  );

  const handleCheck = (id: number, isChecked: boolean) => {
    setCheckedItems((prev) => ({ ...prev, [id]: isChecked }));
  };

  const handleClearAll = () => {
    setCheckedItems({});
  };

  const handleFilter = () => {
    const checkedIds = Object.entries(checkedItems)
      .filter(([_, isChecked]) => isChecked)
      .filter(([id, _]) => id !== "0")
      .map(([id, _]) => id)
      .join(",");

    if (checkedIds.length > 0) {
      // selected-assets-input の value を更新
      const selectedAssetsInput = document.querySelectorAll(
        ".js-selected-assets-input"
      ) as NodeListOf<HTMLInputElement>;
      if (selectedAssetsInput) {
        selectedAssetsInput.forEach((input) => {
          input.value = checkedIds;
        });
      }
    }

    // search-form-spaces フォームを取得してサブミット
    const searchForm = document.getElementById(
      "search-form-spaces-pc"
    ) as HTMLFormElement;
    if (searchForm) {
      searchForm.submit();
    }

    onClose();
  };

  const roleNames: Record<number, string> = {
    0: "器具",
    1: "特徴",
    2: "エアロビ",
    5: "設備",
  };

  if (!isOpen) return null;

  const checkboxClass =
    "mr-3 w-6 h-6 form-checkbox text-white bg-white border-1 border-gray-300 rounded focus:ring-0 focus:ring-offset-0 checked:bg-black";
  const labelClass = "text-base flex items-center";
  const popularDotClass = "w-2 h-2 bg-green-500 rounded-full";

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      onClick={handleOutsideClick}
    >
      <div className="bg-white rounded-lg w-11/12 max-w-4xl h-[90vh] flex flex-col">
        <div className="p-4 border-b flex justify-between items-center">
          <h2 className="text-xl font-bold">詳細条件</h2>
          <button className="text-2xl" onClick={onClose}>
            &times;
          </button>
        </div>

        <div className="p-4 overflow-y-auto">
          {assetsAdvantage.map((category) => (
            <div key={category.role} className="mb-6">
              <div className="mb-2">
                <h4 className="font-normal bg-gray-100 p-3 rounded-lg">
                  {category.role_text}
                </h4>
              </div>
              <div className="grid grid-cols-4 gap-4 p-4">
                {category.contents.map((item) => (
                  <label key={item.id} className={labelClass}>
                    <input
                      type="checkbox"
                      className={checkboxClass}
                      value={item.id}
                      checked={checkedItems[item.id] || false}
                      onChange={(e) => handleCheck(item.id, e.target.checked)}
                    />
                    <span className="flex items-center">
                      {item.body}
                      {item.is_like && (
                        <span className={`${popularDotClass} ml-1`}></span>
                      )}
                    </span>
                  </label>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="p-4 border-t flex justify-between items-center">
          <button className="text-gray-600 underline" onClick={handleClearAll}>
            すべてクリア
          </button>
          <button
            className="bg-green-500 text-white px-6 py-2 rounded-lg flex items-center text-lg font-bold"
            onClick={handleFilter}
          >
            <svg
              className="w-6 h-6 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
            絞り込む
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssetSearchModal;
